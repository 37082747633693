/* Variables */
$primary-color: #00bfae;
$secondary-color: #6c757d;
$background-color: #ffffff;
$hover-background: rgba(0, 191, 174, 0.1); // Vert clair
$text-color: #00695c;
$transition-speed: 0.3s;

/* Account Menu */
.account-menu-overlay {
  position: fixed;
  top: 50px; /* Décalage sous le header */
  right: 20px; /* Décalage du bord droit */
  width: auto; /* Largeur minimale requise pour contenir les items */
  max-width: 300px; /* Largeur maximale */
  background-color: $background-color;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Ombre élégante */
  border-radius: 10px; /* Coins arrondis */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Bordure fine */
  z-index: 1060;
  padding: 10px 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: fadeIn $transition-speed ease-in-out;

  @media (max-width: 1024px) {
    width: 100vw; /* Pleine largeur en mobile */
    max-width: none; /* Désactiver la limite en mobile */
    top: 0;
    right: 0;
    height: 100vh; /* Pleine hauteur */
    border-radius: 0; /* Suppression des coins arrondis */
    padding: 20px;
  }

  .close-btn {
    font-size: 20px;
    color: $secondary-color;
    cursor: pointer;
    text-align: right;
    margin-bottom: 10px;

    &:hover {
      color: $primary-color;
    }
  }

  .account-link {
    display: block;
    color: $text-color;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: background-color $transition-speed, transform $transition-speed;

    &:hover {
      background-color: $hover-background;
      transform: translateX(5px); /* Décalage visuel à droite */
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
