/* Style général de la page */
.access-page-container {
  display: flex;
  flex-direction: column; /* Ajout de cette ligne pour aligner les éléments de manière verticale */
  justify-content: flex-start; /* Centrage du contenu vers le haut */
  align-items: center;
  background-color: #f4f7f6;
  height: 100vh;
  padding: 20px;
  padding-top: 50px; /* Ajouter un espace pour les onglets */
}

/* Conteneur des cartes de connexion et inscription */
.custom-card-container {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  animation: fadeInUp 1s forwards;
  opacity: 0;
  transform: translateY(30px);
  animation-delay: 0.3s;
}

/* Animation fade-in-up */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Assurer que le Tab Container prend toute la largeur */
.tab-container {
  width: 100%;
}

/* Centrage des onglets */
.nav-tabs {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ddd;
  width: 100%;
  margin-bottom: 20px; /* Espacement entre les onglets et le contenu */
}

/* Style des éléments des onglets */
.nav-item {
  margin: 0 10px;
}

.nav-link {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #333;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Onglet actif */
.nav-link.active {
  background-color: #4caf50;
  color: white;
}

/* Onglet au survol */
.nav-link:hover {
  background-color: #45a049;
  color: white;
}

/* Pour les appareils mobiles (taille d'écran < 768px) */
@media (max-width: 768px) {
  .custom-card-container {
    padding: 20px;
    max-width: 95%;
  }

  .nav-link {
    font-size: 14px;
  }
}
