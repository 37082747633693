/* Variables */
$primary-color: #00bfae;
$secondary-color: #00695c;
$shadow-color: rgba(0, 0, 0, 0.1);

/* TopMenu */
.top-menu {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  background-color: #f8f9fa;

  .top-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }

// Dégradé linéaire
$text-gradient: linear-gradient(to right, $primary-color, $secondary-color);

.text-logo {
  font-size: 1.8rem;
  font-weight: bold;
  background: $text-gradient;
  -webkit-background-clip: text; // Nécessaire pour le texte avec dégradé
  -webkit-text-fill-color: transparent;
  text-decoration: none;

  .repair-part {
    font-weight: bold;
  }

  &:hover {
    background: linear-gradient(to right, $primary-color, darken($secondary-color, 10%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

  .search-bar {
    flex-grow: 1; /* Prend tout l'espace disponible */
    margin: 0 15px;

    form {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .search-input {
      flex-grow: 1;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px 0 0 4px;
      height: 42px;
    }

    .search-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border: 1px solid #ccc;
      border-left: none;
      color: $secondary-color;
      width: 50px;
      height: 42px;
      border-radius: 0 4px 4px 0;
      cursor: pointer;

      i {
        font-size: 18px;
        color: $secondary-color;
      }
    }
  }

  .top-links {
    display: flex;
    gap: 15px;

    .nav-link {
      text-decoration: none;
      color: #555;
      font-size: 0.9rem;
      transition: color 0.3s;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}
