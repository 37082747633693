/* ModeEnLigne.scss */
@import '../../scss/buttons'; /* Importation du fichier contenant le mixin */

.mode-en-ligne {
  h1 {
    font-size: 1.5rem; /* Taille adaptée pour mobile */
    margin-bottom: 1.5rem;
  }

  /* Application du Mixin sur le bouton */
  .btn-explore {
    @include button-style(#00bfae, #00695c); /* couleur de fond et couleur au survol */
  }

  .shadow-lg {
    border-radius: 10px;
    margin-bottom: 1rem;
  }

  .list-group-item {
    font-size: 0.9rem; /* Réduction de la taille pour mobile */
    padding: 0.8rem;
  }

  /* Grands écrans */
  @media (min-width: 768px) {
    h1 {
      font-size: 2rem;
    }

    .list-group-item {
      font-size: 1rem;
    }
  }

  @media (min-width: 992px) {
    h1 {
      font-size: 2.5rem;
    }

    .btn-explore {
      font-size: 1.2rem;
      padding: 1rem 2rem;
    }
  }
}
