/* Variables */
$primary-color: #00695c;
$shadow-color: rgba(0, 0, 0, 0.1);
$text-color: #333;
$hover-background: #eaeaea;

.header-desktop {
  background-color: white;
  box-shadow: 0 2px 10px $shadow-color;
  padding: 10px 20px;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; // Permet l'alignement en haut si plusieurs menus
    max-width: 1200px;
    margin: 0 auto;

    .left-section {
      flex: 1;
      display: flex;
      flex-direction: column; // Assure un empilement vertical

      .main-menu {
        margin-top: 10px; // Ajoute un espace entre TopMenu et MainMenu
        background-color: $hover-background;
        padding: 10px 0;
        border-top: 1px solid #ddd;

        ul {
          list-style: none;
          padding: 0;
          display: flex;
          gap: 15px; // Espacement entre les éléments

          li {
            a {
              text-decoration: none;
              color: $text-color;
              font-size: 1rem;
              transition: color 0.3s ease;

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
    }

    .right-section {
      flex: 0;
      display: flex;
      align-items: center;

      .hamburger-account-menu {
        position: relative;

        .hamburger-button {
          background: none;
          border: none;
          color: $text-color;
          font-size: 1rem;
          cursor: pointer;
          transition: color 0.3s ease;

          &:hover {
            color: $primary-color;
          }
        }

        .account-menu-popin {
          position: absolute;
          top: 100%;
          right: 0;
          background: white;
          border: 1px solid #ddd;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 15px;
          z-index: 1000;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 10px;

              a {
                text-decoration: none;
                color: $text-color;
                font-size: 0.9rem;
                transition: color 0.3s ease;

                &:hover {
                  color: $primary-color;
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
