/* Variables */
$primary-color: #00bfae;
$secondary-color: #00695c;

$shadow-color: rgba(0, 0, 0, 0.1);
$hamburger-color: #888;
$item-color: #00695c;
$hover-background: #eaeaea;

/* Header Mobile */
.header-mobile {
  position: relative;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 10px $shadow-color;

  .mobile-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* Hamburger menu */
    .hamburger-container {
      display: flex;
      flex-direction: column;

      .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 25px;
        height: 20px;
        cursor: pointer;

        span {
          display: block;
          height: 3px;
          background-color: $hamburger-color;
          border-radius: 2px;
          transition: all 0.3s ease-in-out;
        }

        &.open span:nth-child(1) {
          transform: rotate(45deg) translateY(7px);
        }

        &.open span:nth-child(2) {
          opacity: 0;
        }

        &.open span:nth-child(3) {
          transform: rotate(-45deg) translateY(-7px);
        }
      }
    }

/* Logo */
.mobile-logo {
  flex: 1;
  text-align: center;

  img {
    max-height: 40px;
    width: auto; /* Assure que la largeur s'ajuste proportionnellement */
    max-width: 200% !important; /* Double la largeur du logo */
  }
}




// Dégradé linéaire
$text-gradient: linear-gradient(to right, $primary-color, $secondary-color);

.text-logo {
  font-size: 1.8rem;
  font-weight: bold;
  background: $text-gradient;
  -webkit-background-clip: text; // Nécessaire pour le texte avec dégradé
  -webkit-text-fill-color: transparent;
  text-decoration: none;

  .repair-part {
    font-weight: bold;
  }

  &:hover {
    background: linear-gradient(to right, $primary-color, darken($secondary-color, 10%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}



    /* Account Section */
    .account-section {
      display: flex;
      align-items: center;
      gap: 10px;

      .nav-link {
        text-decoration: none;
        color: $secondary-color;
        font-size: 1rem;

        &:hover {
          text-decoration: underline;
        }
      }

      .account-menu-icon {
        cursor: pointer;
        font-size: 24px;
        color: $secondary-color;
        transition: color 0.3s ease;

        &:hover {
          color: darken($secondary-color, 10%);
        }

        .user-icon {
          transition: transform 0.3s ease;

          &.active {
            transform: scale(1.1);
            color: lighten($secondary-color, 10%);
          }
        }
      }
    }
  }

  /* Barre de recherche */
  .mobile-search-bar {
    margin-top: 10px;

    .search-input-group {
      display: flex;
      align-items: center;

      .search-input {
        flex: 1;
        border-radius: 20px;
        padding: 10px;
      }

      .search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $secondary-color;
        color: white;

        &:hover {
          background-color: darken($secondary-color, 10%);
        }
      }
    }
  }

  /* Nouveau menu : Brand Menu */
  .brand-menu {
    margin-top: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border-top: 1px solid #ddd;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      margin: 0;
      padding: 0;

      li {
        a {
          text-decoration: none;
          color: $secondary-color;
          font-size: 1rem;

          &:hover {
            color: darken($secondary-color, 10%);
          }
        }
      }
    }
  }
}
