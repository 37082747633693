.confirmation-container {
    background: linear-gradient(135deg, #c1e4c4, #e1f7d5);
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1.5s ease;

    .details {
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
    }
    
    .title {
        font-size: 24px;
        color: #4caf50;
        font-weight: bold;
        margin-bottom: 15px;
        text-transform: uppercase;
        text-align: center;
    }
    
    .repair-info {
        font-size: 16px;
        color: #333;
        padding: 10px;
    }
    
    .info-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
    }
    
    .info-title {
        font-weight: bold;
        color: #555;
        font-size: 16px;
    }
    
    .info-value {
        font-size: 16px;
        color: #000;
        font-weight: 500;
    }
    
    .loading-message {
        font-size: 16px;
        color: #999;
        text-align: center;
        font-style: italic;
    }
    
    .btn-home {
        padding: 10px 20px;
        font-size: 18px;
        font-weight: bold;
        background-color: #4caf50; /* Couleur verte */
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s;
        display: block;
        margin: 20px auto; /* Centre le bouton */
    }
    
    .btn-home:hover {
        background-color: #45a049;
    }
    
    /* Espacement entre les détails et le bouton */
    .text-center.mt-4 {
        margin-top: 30px;
    }

    .alert-message {
        font-size: 16px;
        color: #d9534f; /* Red for error messages */
        font-weight: bold;
        text-align: center;
    }

    /* Effet d'animation pour l'apparition du texte */
    .repair-info {
        animation: fadeIn 1s ease-in-out;
    }
    
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scale(0.9);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
  
    h2 {
      font-size: 2.5rem;
      color: #2b7a78;
      animation: slideInDown 1s ease;
    }

    @keyframes slideInDown {
        from {
            transform: translateY(-50px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
