@import '../../scss/buttons'; 
@import '../../css/variables'; 

/* Styles spécifiques à la page PhoneDetail */
.phone-detail-card {
  margin: auto;
  max-width: 100%; /* S'adapte automatiquement à la largeur de l'écran */
  padding: 1rem;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
    margin-top: 0.5rem;
  }

  /* Boutons */
  .btn-repair {
    @include button-style($primary-color, $button-hover-color, 50px); /* Utilisation du mixin */
    width: 100%;
    padding: 0.75rem;
    text-align: center;
  }
}

// Ajoutez des styles spécifiques pour les écrans plus grands
@media (min-width: 768px) {
  .phone-detail-card {
    max-width: 600px; /* Largeur fixe pour les tablettes et plus */
  }

  /* Boutons */
  .btn-repair {
    width: auto;
    padding: 0.5rem 1rem;
  }
}
