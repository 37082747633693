.container {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    transition: background 0.3s ease-in-out;
  
    &:hover {
      background: #e9ecef; // Légère modification de l'arrière-plan au survol
    }
  }
  
  .card {
    border: none;
    border-radius: 10px;
  
    .list-item {
      transition: transform 0.3s ease, background 0.3s ease;
      
      &:hover {
        transform: scale(1.02); // Effet de zoom léger
        background: #e0f7fa; // Changer de couleur au survol
      }
    }
  }
  
  .btn-success {
    transition: background 0.3s ease, border-color 0.3s ease;
  
    &:hover {
      background-color: #28a745; // Couleur plus foncée au survol
      border-color: #218838;
    }
  }
  