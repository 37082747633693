/* Fichier: PaymentPage.css */
$primary-color: #00bfae;
$secondary-color: #00695c;
.btn {
    background-color: $primary-color; /* Vert Bootstrap */
    color: white;
    border-radius: 50px; /* Coins arrondis */
    font-size: 16px;
    font-weight: bold;
    padding: 10px 30px;
    transition: all 0.3s ease-in-out; /* Animation fluide */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre subtile */
    border: none;
}

.btn:hover {
    background-color: $secondary-color; /* Vert plus foncé au survol */
    transform: translateY(-3px); /* Légère élévation */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Augmente l'ombre */
}

.btn.active {
    background-color: $secondary-color; /* Vert plus intense pour l'onglet actif */
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2); /* Ombre plus marquée */
    transform: translateY(0); /* Pas d'élévation */
    color: white;
}
