$primary-color: #00bfae;
$secondary-color: #00695c;
$text-color: #333;
$background-color: #f4f4f4;
$button-hover-color: #00796b;
$font-family: 'Arial', sans-serif;

.brand-list-section {
  // Style du champ de recherche
  .search-bar {
    width: 100%;  // Prend toute la largeur sur les petits écrans
    padding: 0.8rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 1rem;
  }

  // Liste des marques (Mobile first)
  .brands-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center; /* Centrer les éléments */
  }

  .brand-item {
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    flex: 1 1 45%; /* 2 éléments par ligne sur petits écrans */
    margin-bottom: 1rem; /* Espacement entre les éléments */

    .brand-link {
      text-decoration: none;
      color: $text-color;

      &:hover {
        color: $button-hover-color;
      }
    }

    .brand-logo {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      animation: fadeInUp 0.5s ease forwards;

      &:hover {
        transform: scale(1.1); /* Zoom sur l'image au survol */
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
      }
    }

    .brand-name {
      font-size: 1rem;
      margin-top: 0.5rem;
      font-weight: 500;
    }
  }

  // Pour les écrans plus grands, ajuster la taille des éléments de la liste
  @media (min-width: 768px) {
    .search-bar {
      max-width: 500px;
      margin: 0 auto 2rem;
    }

    .brand-item {
      flex: 1 1 30%; /* 3 éléments par ligne pour les écrans moyens */
    }
  }

  // Pour les écrans encore plus grands (tablettes et ordinateurs de bureau)
  @media (min-width: 1024px) {
    .brand-item {
      flex: 1 1 16%; /* 6 éléments par ligne pour les grands écrans */
    }
  }
}

/* Animation d'apparition en douceur */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
