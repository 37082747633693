$primary-color: #00bfae;
$secondary-color: #00695c;

.account-overview {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  // Prend toute la hauteur de la fenêtre

  // Conteneur des réparations
  .repairs-section {
    flex-grow: 1;  // Permet au contenu de prendre l'espace restant
    padding: 20px;
    background-color: #f8f9fa;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 20px;
    color: $secondary-color;
  }

  .card {
    border: 1px solid $primary-color;
    transition: box-shadow 0.3s, transform 0.2s;
    background-color: #fefefe;

    &:hover {
      box-shadow: 0 4px 15px rgba(0, 123, 255, 0.3);
      transform: scale(1.02);
    }
  }

  .repair-card {
    width: 100%;
    min-height: 180px;
  }

  // *** Mobile-first styles ***
  @media (max-width: 576px) {
    padding: 10px;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }

    .repair-card {
      min-height: 150px;
      width: 100%;
    }

    .repair-card-container {
      width: 100%; // Une carte par ligne en mobile
    }
  }

  // *** Tablette styles ***
  @media (min-width: 576px) and (max-width: 768px) {
    padding: 15px;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 18px;
    }

    .repair-card {
      min-height: 160px;
      width: 100%;
    }

    .repair-card-container {
      width: calc(50% - 10px); // Deux cartes par ligne sur tablette
      max-width: calc(50% - 10px);
    }
  }

  // *** Bureau styles ***
  @media (min-width: 768px) {
    padding: 20px;

    h2 {
      font-size: 2rem;
      margin-bottom: 20px;
    }

    .repair-card {
      min-height: 200px;
      width: 100%;
    }

    .repairs-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .repair-card-container {
      width: calc(33.33% - 20px);  // Trois cartes par ligne (avec espacement)
      max-width: calc(33.33% - 20px); // Limite la largeur maximale
    }
  }
}

// **Footer**
footer {
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
  position: relative;
}
