/* Variables pour la gestion des couleurs et des polices */
$primary-color: #00bfae;
$secondary-color: #00695c;
$text-color: #333;
$background-color: #f4f4f4;
$button-hover-color: #00796b;
$font-family: 'Arial', sans-serif;

/* Breakpoints pour la responsivité */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

/* App.scss */

/* Style global du body */
body {
  margin: 0;
  padding: 0;
  display: block;
  min-height: 100vh;
  font-family: $font-family;
  color: $text-color;
  background-color: $background-color;
}

/* Conteneur principal de l'application */
.App {
  display: block;
  height: 100%;
  background-color: $background-color;
}

/* Section principale */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Styles pour les sections */
.section {
  width: 100%;
  padding: 3rem 1rem;
  text-align: center;
  background: linear-gradient(to right, $primary-color, $secondary-color);
  color: #fff;
  margin-bottom: 3rem;

  h2, .heading, .subheading, p {
    color: inherit;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    animation: fadeIn 1s ease-out;
  }

  .subheading {
    font-size: 1rem;
    margin-bottom: 1rem;
    animation: fadeIn 1s ease-out 0.2s;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    animation: fadeIn 1s ease-out 0.3s;
  }

  /* Styles des boutons supprimés */
}

/* Animation fadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation bounceIn */
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  60% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Footer */
footer {
  margin-top: auto;
}

/* Responsivité */

/* Petits écrans (mobiles) */
@media (max-width: $breakpoint-sm) {
  .section {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 1.8rem;
  }

  .subheading {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
  }
}

/* Tablettes */
@media (max-width: $breakpoint-md) {
  .heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
  }
}

/* Grands écrans (desktop) */
@media (min-width: $breakpoint-lg) {
  .section {
    padding: 5rem 2rem;
  }

  .heading {
    font-size: 2.5rem;
  }

  .subheading {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.25rem;
  }
}
