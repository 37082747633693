$primary-color: #00bfae;
$secondary-color: #00695c;

.mode-reparation-container {
  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    color: $secondary-color; // Couleur mise à jour pour le titre principal
  }

  .nav-pills .nav-link {
    font-size: 1.1rem;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 50px;
    color: #6c757d;

    &.active {
      background-color: $secondary-color;
      color: #fff;
    }
  }

  .card {
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    }

    .card-title {
      font-weight: bold;

      &.text-primary {
        color: $secondary-color !important; // Utilisation du vert pour "Réparation En Ligne"
      }

      &.text-secondary {
        color: $secondary-color; // Utilisation du vert foncé pour "Réparation En Boutique"
      }
    }

    .btn-explore {
      font-size: 1rem;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 50px;
      transition: background-color 0.3s ease, color 0.3s ease;
      width: auto !important; // Ajuste la largeur au contenu
    display: inline-block; // Assure le centrage du texte

      // Couleurs par défaut pour les deux types de boutons
      &.btn-primary {
        background-color: $primary-color;
        color: #fff;

        &:hover {
          background-color: $secondary-color; // Nouvelle couleur de survol pour "En Ligne"
          color: #e0f7fa;
        }
      }

      &.btn-secondary {
        background-color: #6c757d;
        color: #fff;

        &:hover {
          background-color: #495057; // Nouvelle couleur de survol pour "En Boutique"
          color: #e0f7fa;
        }
      }

      &.disabled {
        background-color: #c0c0c0; // Couleur pour les boutons désactivés
        color: #ffffff;
        cursor: not-allowed;
      }
    }
  }
}
