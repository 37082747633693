$primary-color: #00bfae;
$secondary-color: #00695c;
$text-color: #333;
$background-color: #f9f9f9;

.about-container {
  background-color: $background-color;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .about-wrapper {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 800px;
    width: 100%;
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;

    .about-section {
      margin-bottom: 30px;

      .about-title {
        font-size: 1.8em;
        font-weight: bold;
        color: $primary-color;
        margin-bottom: 20px;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
        animation: slideIn 1s ease-out;

        @media (min-width: 768px) {
          font-size: 2.5em;
        }
      }

      .about-description {
        font-size: 1.1em;
        color: $text-color;
        margin-bottom: 15px;
        line-height: 1.6;

        @media (min-width: 768px) {
          font-size: 1.2em;
        }
      }

      .about-tagline {
        font-size: 1.3em;
        color: $secondary-color;
        font-weight: bold;
        margin-top: 10px;

        @media (min-width: 768px) {
          font-size: 1.5em;
        }
      }
    }

    .social-media-section {
      .social-media-title {
        font-size: 1.8em;
        margin-bottom: 20px;
        color: $secondary-color;
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-size: 2em;
        }
      }

      .social-links {
        display: flex;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;

        .social-link {
          font-size: 1.2em;
          padding: 10px 20px;
          color: white;
          border-radius: 8px;
          text-decoration: none;
          transition: transform 0.3s ease, background-color 0.3s ease;

          &.facebook {
            background-color: #3b5998;
          }

          &.instagram {
            background: radial-gradient(circle at 30% 30%, #fdf497, #fd5949, #d6249f, #285aeb);
          }

          &.twitter {
            background-color: #1da1f2;
          }

          &.youtube {
            background-color: #ff0000;
          }

          &:hover {
            transform: scale(1.1);
            filter: brightness(1.2);
          }

          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

/* Animation pour fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation de slide-in */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
