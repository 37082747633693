/* Mixin pour les boutons */
@mixin button-style($background-color, $hover-color, $radius: 50px, $padding: 0.5rem 1rem) {
    font-size: 1rem;
    background-color: $background-color;
    color: #fff;
    border: none;
    border-radius: $radius;
    padding: $padding;
    transition: all 0.3s ease;
    width: auto !important; /* La largeur s'ajuste automatiquement */
  
    &:hover {
      background-color: $hover-color;
    }
  }
  