// Variables
$primary-color: #00bfae;
$secondary-color: #00695c;
$background-color: #f4f4f4;
$service-title-bg-color: #f0f0f0; 
$font-family-apple: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Global Styles
body {
  font-family: $font-family-apple;
  margin: 0;
  padding: 0;
  background-color: #fff;
  line-height: 1.6;
  font-size: 16px;
  color: #333;
}

// Titres, Sous-titres et Paragraphes
h1, h2, h3 {
  font-family: $font-family-apple;
  font-weight: 700;
  color: $secondary-color;
  margin-bottom: 20px;
}

h1 {
  font-size: 32px; // Taille du titre principal
}

h2 {
  font-size: 24px; // Taille du sous-titre principal
}

h3 {
  font-size: 20px; // Taille du sous-sous-titre
}

p {
  font-family: $font-family-apple;
  font-size: 1rem; // Taille du texte pour les paragraphes
  color: #333;
  margin-bottom: 20px;
}

// Repair Services Section
.repair-services {
  max-width: 100%;
  margin: 10px auto;
  padding: 15px;
  background-color: $background-color;
  border-radius: 10px;

  h1 {
    font-size: 32px;
    font-weight: 700;
    color: $secondary-color;
    text-align: left;
    margin-bottom: 24px;
    line-height: 1.2;
  }

  .expertise {
    color: #fff;
    background: linear-gradient(to right, $primary-color, $secondary-color);
    padding: 20px;
    text-align: center;
    border-radius: 10px;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
    }

    p {
      font-size: 1.2rem;
      color: #fff;
      margin: 0;
    }
  }
}

// Services Grid
.services {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  .service-card {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer; 
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px); /* Effet de levitation */
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Ombre plus marquée */
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #6e6e73; // Gris sympa pour le titre des services
      background-color: $service-title-bg-color; // Fond gris-vert clair pour le titre du service
      padding: 10px;
      border-radius: 4px;
    }

    .service-description {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      margin-top: 10px;
      font-size: 1rem;
      color: #6e6e73; /* Gris pour la description */
      line-height: 1.5;
      transition: opacity 0.3s ease, max-height 0.3s ease;
    }

    .service-description.show {
      max-height: 500px;
      opacity: 1;
    }
  }
}

// Responsive Styles
@media (min-width: 768px) {
  .services {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Plus large sur les grands écrans */
  }

  .expertise h2 {
    font-size: 24px;
  }

  .service-card h2 {
    font-size: 20px;
  }

  body {
    font-size: 18px;
  }
}

// Loading & Error Styles
.loading,
.error {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  color: $secondary-color;
}
