.account-details {
    padding: 20px;
  
    h4 {
      margin-bottom: 20px;
    }
  
    .card {
      border: 1px solid #007bff; // Bordure colorée pour les cartes
      transition: box-shadow 0.3s;
  
      &:hover {
        box-shadow: 0 4px 15px rgba(0, 123, 255, 0.3); // Ombre au survol
      }
    }
  
    // Mobile First: pour les petits écrans
    @media (max-width: 576px) {
      padding: 10px;
  
      h4 {
        font-size: 1.5rem; // Réduire la taille du titre sur les petits écrans
        margin-bottom: 15px;
      }
  
      .card {
        padding: 10px; // Réduire la taille de la carte sur les petits écrans
      }
    }
  
    // Tablette: pour les écrans moyens
    @media (min-width: 576px) and (max-width: 768px) {
      padding: 15px;
  
      h4 {
        font-size: 1.8rem;
        margin-bottom: 18px;
      }
  
      .card {
        padding: 15px;
      }
    }
  
    // Bureau: pour les écrans larges
    @media (min-width: 768px) {
      .card {
        padding: 20px;
      }
    }
  }
  