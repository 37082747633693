$primary-color: #00bfae; // Vert
$secondary-color: #6c757d; // Gris
$background-color: #ffffff; // Blanc
$hover-background-color: #dff5e0; // Vert pâle
$text-color: #00695c; // Gris foncé
$transition-speed: 0.3s;

.mobile-menu {
  background: $background-color;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .close-btn {
    font-size: 24px;
    color: $secondary-color;
    padding: 15px;
    cursor: pointer;
    text-align: right;
    margin-right: 10px;
    transition: color $transition-speed;

    &:hover {
      color: $primary-color;
    }
  }

  .navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;

    .nav-item {
      padding: 10px 20px;

      .nav-link {
        display: block;
        color: $text-color;
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;
        background-color: $background-color;
        border-radius: 5px;
        padding: 10px;
        transition: all $transition-speed ease;

        &:hover {
          background-color: $hover-background-color;
          color: $primary-color;
          transform: scale(1.05);
        }

        &.dropdown-toggle {
          cursor: pointer;
          position: relative;

          &:after {
            content: '▼';
            font-size: 12px;
            color: $secondary-color;
            margin-left: 8px;
            transition: transform $transition-speed;
          }

          &.active:after {
            transform: rotate(180deg);
          }
        }
      }

      .dropdown-menu {
        background-color: $background-color;
        border: 1px solid lighten($secondary-color, 20%);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-top: 5px;
        padding: 0;

        .dropdown-item {
          font-size: 16px;
          padding: 10px 20px;
          color: $text-color;
          text-decoration: none;
          transition: background-color $transition-speed, color $transition-speed;

          &:hover {
            background-color: $primary-color;
            color: $background-color;
          }
        }
        .dropdown-link {
            color: $primary-color; // Vert défini en variable
            text-decoration: none;
            font-weight: 500; // Plus lisible
            font-size: 16px;
            transition: color 0.3s ease;
          
            &:hover {
              color: darken($primary-color, 10%); // Couleur légèrement plus foncée au survol
              text-decoration: underline; // Facultatif pour un retour visuel
            }
          }
          
      }
    }
  }
}
