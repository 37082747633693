/* variables.scss */

/* Couleurs */
$primary-color: #00bfae;
$secondary-color: #00695c;
$button-hover-color: #005f4f;
$text-color: #333;

/* Typographie */
$font-family: 'Arial', sans-serif;

/* Breakpoints */
$breakpoint-md: 768px;

/* Autres variables */
$button-radius: 50px;
