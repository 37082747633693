.recap-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 100%;
  margin-top: 2rem;
}

.recap-header {
  background-color: #00bfae; // Couleur du fond
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 1rem;
}

.choice-item {
  font-size: 1.1rem;
  padding: 0.8rem;
  border: none;
  background-color: #f9f9f9; // Légère couleur de fond pour l'élément
}

.choice-item:hover {
  background-color: #e2e2e2; // Couleur de fond au survol
}

.service-item {
  font-size: 1rem;
  padding-left: 2rem;
}

.badge-custom {
  background-color: #ccc; // Changez la couleur de fond
  color: white; // Couleur du texte
}

.text-start {
  text-align: left !important;
}
