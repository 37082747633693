.repair-history-scroll {
  max-height: 400px;  /* Limite la hauteur de la table */
  overflow-y: auto;   /* Active le défilement vertical */
}

@media (max-width: 576px) {
  .repair-history-scroll {
    max-height: 300px;  /* Réduit la hauteur sur les petits écrans */
  }

  table {
    font-size: 0.9rem;  /* Ajuste la taille de la police pour mobile */
  }

  th, td {
    padding: 8px;  /* Réduit l'espacement pour les petits écrans */
  }

  h2 {
    font-size: 1.5rem;  /* Réduit la taille du titre */
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .repair-history-scroll {
    max-height: 350px;  /* Ajuste la hauteur pour les tablettes */
  }

  table {
    font-size: 1rem;  /* Ajuste la taille de la police pour les tablettes */
  }

  th, td {
    padding: 10px;
  }

  h2 {
    font-size: 1.8rem;  /* Augmente légèrement la taille du titre */
  }
}

@media (min-width: 768px) {
  .repair-history-scroll {
    max-height: 400px;  /* Garde la même hauteur pour les grands écrans */
  }

  table {
    font-size: 1.1rem;  /* Agrandit légèrement la taille de la police */
  }

  th, td {
    padding: 12px;  /* Augmente l'espacement pour les grands écrans */
  }

  h2 {
    font-size: 2rem;  /* Agrandit encore la taille du titre */
  }
}
