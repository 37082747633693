// Définition de variables pour la gestion des couleurs et des polices
$primary-color: #00bfae;
$secondary-color: #00695c;
$text-color: #333;
$background-color: #f4f4f4;
$button-hover-color: #00796b;
$font-family: 'Arial', sans-serif;

.account-page {
  background: linear-gradient(to right, #f0f4f8, #e2e6ea); // Gradient de fond
  height: 100vh;

  .sidebar {
    background: linear-gradient(to right, #f0f4f8, #e2e6ea); // Gradient de fond
    color: #ffffff;
    padding: 20px;
    height: 100vh; // Remplit la hauteur de la fenêtre
    position: sticky;
    top: 0; // Reste en haut lors du défilement

    h4 {
      margin-bottom: 20px;
      font-weight: bold;
    }

    .nav-link {
      color: #00796b;
      transition: background 0.3s, color 0.3s;

      &:hover {
        background-color: #00bfae; // Changement de couleur au survol
        color: #ffffff;
      }

      &.active {
        background-color: #00bfae; // Couleur pour l'élément actif
        color: #ffffff;
      }
    }
  }

  .content {
    padding: 30px;
    h2 {
      margin-bottom: 20px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      color: #333;
    }

    // Animation d'entrée
    animation: fadeIn 0.5s ease-in;

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  // Media Queries pour rendre la page responsive
  @media (max-width: 768px) {
    .sidebar {
      position: relative;
      height: auto;
      padding: 15px;
    }

    .content {
      padding: 20px;
    }
  }

  @media (max-width: 576px) {
    .sidebar {
      padding: 10px;
      h4 {
        font-size: 16px;
      }

      .nav-link {
        font-size: 14px;
        padding: 8px;
      }
    }

    .content {
      padding: 15px;
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
