@import 'variables'; 
@import '../scss/buttons'; 

/* Mobile-first styles */
.homepage {
  font-family: $font-family;
  font-size: 1rem;
  color: $text-color;

  .fixes-in-a-flash {
    background: linear-gradient(to right, $primary-color, $secondary-color);
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    color: #fff; /* Texte en blanc */

    .heading {
      font-size: 1.75rem;
      color: #fff; /* Texte en blanc pour le titre */
    }

    .subheading {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: #fff; /* Texte en blanc pour le sous-titre */
    }

    .btn-reserve {
      @include button-style($primary-color, $button-hover-color); /* Utilisation du mixin */
    }
  }

  /* Cards Section */
  #cards-section {
    .card {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      min-height: 300px;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
      }

      .btn {
        margin-top: 1rem;
        @include button-style($primary-color, $button-hover-color); /* Utilisation du mixin */
      }

      .btn-explore, .btn-contact {
        @include button-style($primary-color, $button-hover-color); /* Utilisation du mixin */
      }
    }
  }
}

/* Styles spécifiques aux écrans plus larges (desktop) */
@media (min-width: $breakpoint-md) {
  .homepage {
    font-size: 1.25rem;

    .fixes-in-a-flash {
      padding: 3rem 2rem;

      .heading {
        font-size: 2.5rem;
        color: #fff; /* Texte en blanc pour le titre sur desktop */
      }

      .subheading {
        font-size: 1.25rem;
        color: #fff; /* Texte en blanc pour le sous-titre sur desktop */
      }
    }

    #cards-section {
      .card {
        .card-body {
          text-align: left;
        }
      }

    /* Réduction de la taille des boutons en desktop */
    .btn {
      font-size: 0.875rem; /* Réduit la taille de la police */
      padding: 0.25rem 0.75rem; /* Réduit le padding */
    }
  }
}
}