.main-menu {
  
  padding: 15px 0;



  .main-menu-container {
    background: transparent;
    display: flex;
    justify-content: center;  /* Centrer les éléments */
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;  /* Pour que le conteneur occupe toute la largeur disponible */
  }

  .logo img {
    max-height: 40px;
  }

  .nav-links ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  .nav-links ul li {
    margin: 0 15px;  /* Ajouter un petit espace entre les éléments */
  }

  .nav-links ul li a {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
  }

  .nav-links ul li a:hover {
    color: #007bff;
  }
}
