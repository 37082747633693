.search-button {
    background-color: transparent !important; /* Fond blanc */
    border: 1px solid #ccc; /* Optionnel : bordure grise */
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px; /* Coins arrondis, optionnel */
  }
  
  .search-button i {
    font-size: 1.25rem;
    color: #00695c; /* Couleur de l'icône */
  }
  
  .search-button:hover {
    background-color: #f8f9fa; /* Léger effet au survol */
  }
  
  