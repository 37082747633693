@import '../../scss/buttons'; // Importer les styles de boutons

.service-card {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }

  .service-name {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }

  .service-price {
    font-size: 0.9rem;
    color: #444;
  }

  .service-description {
    font-size: 0.85rem;
    color: #666;
  }
}

@media (min-width: 768px) {
  .service-card {
    padding: 20px;
  }

  .service-name {
    font-size: 1.25rem;
  }
}

// Boutons utilisant le mixin
.btn-primary {
  @include button-style(#00bfae, #00695c); // Vert avec hover bleu foncé
}

.btn-primary {
  width: 100%;
}

.btn-secondary {
  @include button-style(#ff5722, #d84315); // Orange avec hover plus foncé
}

.btn-danger {
  @include button-style(#f44336, #d32f2f); // Rouge avec hover plus foncé
}
