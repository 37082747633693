.phone-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre le contenu horizontalement */
  justify-content: center; /* Centre le contenu verticalement */
  text-align: center; /* Centre le texte */
}

.phone-image {
  width: 120px; /* Réduit la largeur de l'image */
  height: 120px; /* Réduit la hauteur de l'image */
  object-fit: contain; /* L'image conserve son ratio d'aspect sans être coupée */
  margin-bottom: 1rem; /* Espace entre l'image et le texte */
}
