/* Variables communes */
$primary-color: #00bfae;
$secondary-color: #00695c;
$text-color: #333;
$background-color: #f4f4f4;
$font-family: 'Arial', sans-serif;
$button-radius: 50px; /* Arrondi des boutons */
$button-hover-color: #00796b;

/* Breakpoints pour la responsivité */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

/* Style global du body */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $font-family;
  color: $text-color;
  background-color: $background-color;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1; /* Cette propriété force le contenu à occuper toute la hauteur restante */
}

footer {
  margin-top: auto;  /* Pousse le footer en bas du conteneur */
}


/* Section de contenu */
.section {
  padding: 2rem 1rem;
  flex-grow: 1; /* Assure que le contenu prend toute la place disponible */
}

/* Responsivité - Mobile first */

/* Petits écrans (mobiles) */
@media (max-width: $breakpoint-sm) {
  .section {
    padding: 1.5rem 1rem; /* Réduction du padding sur petits écrans */
  }

  .heading {
    font-size: 1.8rem; /* Taille du texte réduite pour mobiles */
  }

  .subheading {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
  }
}

/* Tablettes */
@media (max-width: $breakpoint-md) {
  .heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
  }
}

/* Grands écrans (desktop) */
@media (min-width: $breakpoint-lg) {
  .heading {
    font-size: 2.5rem;
  }

  .subheading {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.25rem;
  }
}
